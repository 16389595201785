<template>
    <div style="width:100%">
        <div class="of-form-row">
            <el-form-item label="Instrument" prop="isin">
                <el-select 
                    v-model="formdata.isin"
                    @change="load_isin"
                    disabled>
                    <el-option
                        v-for="item in isin_list"
                        :key="item.isin"
                        :label="item.isin"
                        :value="item.isin"
                        v-if="item.q">
                        <div class="flex_c" style="height: 100%;">
                            <div class="of-search-isin-isin">{{ item.isin }}</div>
                            <div class="of-search-isin-price" style="font-size:11px;">
                                <FormattedNumber
                                    :value="item.q" :decimals="2" color />
                            </div>
                        </div>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Quantity" prop="q">
                <el-input
                    v-model="formdata.q"
                    @blur="blur_format_field('q')"
                    :disabled="!this.isin_data.isin" />
            </el-form-item>
        </div>
    </div>
</template>

<script>
import common from './operation_mixin'
import FormattedNumber from '@/components/Formats/FormattedNumber'

export default {
    mixins: [common],
    components: { FormattedNumber },

    props: {
        asset: {
            type: String,
            required: true
        },
        client_id: {
            type: String,
            required: true
        }
    },

    data(){
        return {
            isin_list: []
        }
    },

    methods: {
        load_isin_list(){
            this.clear_data()
            this.isin_list = []
        },

        load_isin(isin){
            this.$set(this.formdata, 'isin', isin);
            this.$store.dispatch('instrument/getInstrumentShort', { asset: this.asset, isin: isin, dt:this.trade_date }).then((response) => {
                this.change_isin(response)
            })
        },

        change_isin(item){
            this.clear_data()

            this.$set(this.formdata, 'isin', item.isin);
            this.isin_data = { isin : this.formdata.isin };
            this.set_isin_data(item)
        },
    },

    watch: {
        asset(){
            this.load_isin_list()
        }
    },

    mounted(){
        this.load_isin_list()
    }
}
</script>
